@media (min-width: 1500px) {
  nav {
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    transform: rotate(-90deg) translate3d(-50%,0,0);
    top: 15%;
    left: -6.5rem;
  }
}
nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #575757;
}
nav a:hover {
  margin-right: 20px;
  text-decoration: none;
  color: #ec704c;
}
nav a.active {
  color: #ec704c;
}
@media (max-width: 1500px) {
  nav {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: row;
    transform: none;
    top: 11%;
    margin:auto;
    width: 50%;
    left: 25%;
    z-index: 0;
  }
  nav a {
    text-decoration: none;
    color: #969696;
  }

}