#root{
  overflow-x: hidden;
  scrollbar-width: none;
}
.project {
  width: 80%;
  position: relative;
  height: 100%;
  background-color: #ec704c;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.__img_wrapper {
  width: 50%;
  height: 100%;
  position: relative;
}
.__content_wrapper {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
.title {
  font-size: 1.5rem;
  margin: 0;
}
.description {
  font-size: 1rem;
  width: 60%;
}


#image-track-parent{
  width:100%;
  left: 25%;
  height: 100%;
  --scrollbar-width: 0px;
  position: relative;
  background-color: #333;
  /* mask fade distance, for use in mask calculations */
  --mask-distance: 200px;

  /* If content exceeds height of container, overflow! */
  /*overflow-x: auto;

  /* Our height limit */

  /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */

  /* Keep some space between content and scrollbar */


  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content-right: linear-gradient(
      to right,
      transparent,
      black var(--mask-distance),
      black calc(100% - var(--mask-distance)),
      transparent
  );
  --mask-image-content-left: linear-gradient(
      to left,
      transparent,
      black var(--mask-distance),
      black calc(100% - var(--mask-distance)),
      transparent
  );

  /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content-right), var(--mask-image-scrollbar),var(--mask-image-content-left);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
  mask-position: 50%, 50%, 100% 100%;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
  
}
#image-track {
  display: flex;
  gap: 4vmin;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;

  
  transform: translate(0%, -50%); 
  -webkit-transform: translate(0%, -50%); 
  user-select: none; /* -- Prevent image highlighting -- */
}
.project-card
{
  width: 40vmin;
  object-fit: cover;
  border-radius: 25px;
}

#image-track > .project-card > .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    object-position: 100% center;
}
.image-singular-full{
  object-fit: cover;
  height: auto;
  width:100%;
  margin-bottom: 5%;
 
  box-shadow: 3px 3px 5px -1px rgba(0,0,0,1),3px 6px 10px 0px rgba(0,0,0,0.14),3px 1px 18px 0px rgba(0,0,0,0.12);
}
.image-singular-full-small{
  object-fit: cover;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-bottom: 5%;
  box-shadow: 3px 3px 5px -1px rgba(0,0,0,1),3px 6px 10px 0px rgba(0,0,0,0.14),3px 1px 18px 0px rgba(0,0,0,0.12);
}

.project-fullscreen{
  position:  fixed;
  width:100%;
  height:100%;
  top:0;
  left: 0;
  opacity: 1;
  z-index: 3;
  background-color: #333;
}
.project-fullscreen-content-parent{
  width:40%;
  left: 30%;
  top:15%;
  z-index: 10;
  height: 80%;
  --scrollbar-width: 0px;
  position: relative;
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 0px;

  /* mask fade distance, for use in mask calculations */
  --mask-height: 50px;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;


  /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */
  padding-bottom: var(--mask-height);


  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
      to bottom,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
  );

  /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
  
}
.project-fullscreen-title{
  position:fixed;
  width:100%;
  text-align: center;
  
}
.exit-fullscreen{
  position: fixed;
  right: 5%;
  top:5%;
}
.project-fullscreen-navbar{
  position: fixed;
  text-align: left;
  font-size: 1.25rem;
  width: 15%;
  top: 20%;
  list-style: none;
  margin: 0;
  padding: 0;
  left:15%;
}

.active {
  font-weight: bold;
  color: #ec704c;
}

.project-fullscreen-content {
  width:100%;
  max-height: 100vh;
  padding-top: 5%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-left: 1px solid #333;
  padding-left: 5%;
  padding-right: 5%;
}

.sec {
  padding: 0;
  font-size: 1.4rem;
  text-align: center;
  scroll-snap-align: start;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.sections {
  
  display: flex;
  flex-direction: column;
  gap: 10vh;
  transition: transform 1s ease-out;
}
.project-fullscreen-content.scrolling {
  transform: translateY(-100vh * scrollTop);
}
.markdown-content{
  padding-top: 5%;
}
.content-text{
  padding-bottom: 3%;
  text-align: left;
}
.row {
  display: flex;
  padding-bottom: 5%;
}
.column-image {
  width: 35%;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: 3px 3px 5px -1px rgba(0,0,0,1),3px 6px 10px 0px rgba(0,0,0,0.14),3px 1px 18px 0px rgba(0,0,0,0.12);
}
.project-card-text-parent
{
    width:100%;
    height:20%;
    position: relative;
    top:-25%;
}
.project-card-title-text
{
    width:100%;
    font-size: 0.5rem;
    text-align: center;
}
.project-card-button
{
    position:relative;
    width:50px;
    height:50px;
    left:20px;
    bottom:20px;
}

@media (max-width: 1500px) {
  .makeStyles-main-11{
    height: 80vh;
  }
  .sections {
  
    display: flex;
    flex-direction: column;
    gap: 5vh;
    transition: transform 1s ease-out;
    overflow-x: hidden;
  }
  #root{
    overflow-y: hidden;
  }
  .project {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
  
  .__img_wrapper {
    width: 80%;
    height: 100%;
   
  }
  #image-track-parent{
    width:100%;
    left: 25%;
    height: 100%;
    --scrollbar-width: 0px;
    position: relative;
  
    /* mask fade distance, for use in mask calculations */
    --mask-distance: 200px;
  
    /* If content exceeds height of container, overflow! */
    /*overflow-x: auto;
  
    /* Our height limit */
  
    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
  
    /* Keep some space between content and scrollbar */
  
  
    /* The CSS mask */
  
    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content-right: linear-gradient(
        to right,
        transparent,
        black var(--mask-distance),
        black calc(100% - var(--mask-distance)),
        transparent
    );
    --mask-image-content-left: linear-gradient(
        to left,
        transparent,
        black var(--mask-distance),
        black calc(100% - var(--mask-distance)),
        transparent
    );
  
    /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  
    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);
  
    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
  
    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content-right), var(--mask-image-scrollbar),var(--mask-image-content-left);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  
    /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
    mask-position: 50%, 50%, 100% 100%;
  
    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
    
  }
  #image-track-parent{
    width:96vw;
    height: 100%;
    --scrollbar-width: 0px;
    position: relative;
    left:-43%;
    --mask-distance: 50px;
  }
  
  
  #image-track > .project-card > .image {
    width: 70vw;
    height: 60vh;
    object-fit: cover;
    box-shadow: 3px 3px 5px -1px rgba(0,0,0,1),3px 6px 10px 0px rgba(0,0,0,0.14),3px 1px 18px 0px rgba(0,0,0,0.12);
    object-position: 100% center;
}
  .project-card
  {
    width: 10%;
    height: 10%;
    object-fit: cover;
    box-shadow: 3px 3px 5px -1px rgba(0,0,0,1),3px 6px 10px 0px rgba(0,0,0,0.14),3px 1px 18px 0px rgba(0,0,0,0.12);
  }
  .project-fullscreen-content-parent{
    width:96%;
    left: 2%;
    top:19%;
    height: 80%;
    overflow-x: hidden;
    --scrollbar-width: 0px;
    position: relative;
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 0px;
  
    /* mask fade distance, for use in mask calculations */
    --mask-height: 50px;
  
    /* If content exceeds height of container, overflow! */
    overflow-y: auto;
  
  
    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
    padding-bottom: var(--mask-height);
  
  
    /* The CSS mask */
  
    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );
  
    /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  
    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);
  
    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
  
    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  
    /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;
  
    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
    
  }
  #image-track {
    display: flex;
    gap: 10vmin;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
  
    
    transform: translate(0%, -50%); 
    user-select: none; /* -- Prevent image highlighting -- */
  }
  .project-fullscreen-content {
    width:100%;
    max-height: 100vh;
    padding-top: 5%;
    overflow-y: auto;
    scroll-behavior: smooth;
    border-left: 0px solid #333;
    padding-left: 5%;
    overflow-x: hidden;
    padding-right: 5%;
  }
  .project-fullscreen-navbar {
    position: fixed;
  text-align: center;
  font-size: 1rem;
  width: 90%;
  top: 15%;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 5%;
  display: flex;
  gap: 10vw;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  }
  .my_work {
    font-size: 5rem;
    margin-top: 10rem;
    margin-bottom: 10rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .project-fullscreen-navbar::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(51,51,51,0) 0%, rgba(51,51,51,1) 50%, rgba(51,51,51,0) 100%);
  }
  
  .project-fullscreen-navbar li h3{
    flex-shrink: 1;
    font-size: 3.5vw;
  }
  .exit-fullscreen{
    position: fixed;
    right: 3%;
    top:3%;
  }
  .project-fullscreen-title{
    position:fixed;
    width:80%;
    left: 10%;
    height:auto;
    text-align: center;
  }
  .project-fullscreen-title h2{
    font-size: 9vw;
  }
  
}
.filler-div{
  width:100%;
  height:30vh;
}
.filler-div-small{
  width:100%;
  height:5vh;
}
.projectItem:hover {
  transform: scale(1.5);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.7)
}
.videoPlayer
{
  width:100%;
  aspect-ratio: 16 / 9;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
