* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
::-moz-selection {
  background: #00bfbf;
  color: #fafafa;
  text-shadow: none;
}
::selection {
  background: #00bfbf;
  color: #fafafa;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  transition: all 0.5s ease;
}
p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
@media (max-width: 1500px) {
  .fixed-mobile-header-parent{
    height:100vh;
    width:100vw;
    position: fixed;
    top:0;
    left:0;
    overflow: hidden;
  }
  .fixed-mobile-header{
      width:100%;
      top:13%;
      height: 80%;
      --scrollbar-width: 0px;
      position: absolute;
      z-index: 0;
      /* scroll bar width, for use in mask calculations */
      --scrollbar-width: 0px;
    
      /* mask fade distance, for use in mask calculations */
      --mask-height: 75px;
    
      /* If content exceeds height of container, overflow! */
      overflow-y: auto;
      overflow-x: hidden;
    
      /* Need to make sure container has bottom space,
    otherwise content at the bottom is always faded out */
      padding-bottom: var(--mask-height);
  
    
      /* The CSS mask */
    
      /* The content mask is a linear gradient from top to bottom */
      --mask-image-content: linear-gradient(
          to bottom,
          transparent,
          black var(--mask-height),
          black calc(100% - var(--mask-height)),
          transparent
      );
    
      /* Here we scale the content gradient to the width of the container 
    minus the scrollbar width. The height is the full container height */
      --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    
      /* The scrollbar mask is a black pixel */
      --mask-image-scrollbar: linear-gradient(black, black);
    
      /* The width of our black pixel is the width of the scrollbar.
    The height is the full container height */
      --mask-size-scrollbar: var(--scrollbar-width) 100%;
    
      /* Apply the mask image and mask size variables */
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    
      /* Position the content gradient in the top left, and the 
    scroll gradient in the top right */
      mask-position: 0 0, 100% 0;
    
      /* We don't repeat our mask images */
      mask-repeat: no-repeat, no-repeat;
  }
}
@media (min-width: 1501px) {
  .fixed-mobile-header-parent{
    height:100vh;
    width:100vw;
    position: fixed;
    top:0;
    left:0;
    overflow: hidden;
  }
  .fixed-mobile-header{
      width:100%;
      height: 100%;
      overflow-y: auto;
      
  }
}
